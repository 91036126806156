import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../_store";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { CategoryModel } from "../models/category/CategoryModel";
import { GetCharacteristicsFromString, ProductCharacteristis, ProductionModel, SetCharacteristicsToString } from "../models/production/ProductionModel";
import { Clipboard2Heart, Pencil } from "react-bootstrap-icons";
import { categoryAdd, categoryDelete, categoryUpdate } from "../_store/thunks/category.thunk";
import { productionAdd, productionDelete, productionUpdate } from "../_store/thunks/production.thunk";
import { FILE_DOWNLOAD, FILE_UPLOAD } from "../constants/endpoints";
import { fetchWrapper } from "../_helpers/fetch-wrapper";
import EditNewsComponent from "../components/editNews/EditNewsComponent";
import { AlertModel } from "../models/AlertModel";
import CustomAlert from "../components/Alert";

export default function AdminPage() {

    const dispatch = useDispatch<AppDispatch>()

    const allCategoriesWithSubcategories = useSelector((state: RootState) => state.category.categoriesWithSubcategoriesList);
    const allProductions = useSelector((state: RootState) => state.production.productions);

    // ------ productions and categories -------
    const [productions, setProductions] = useState<ProductionModel[]>();
    const [categoriesWithSubcategories, setCategoriesWithSubcategories] = useState<{
        mainCategory: CategoryModel,
        subcategories: CategoryModel[]
    }[]>();

    const [currentCategory, setCurrentCategory] = useState<any | null>(null);
    const [currentProduct, setCurrentProduct] = useState<any | null>(null);
    const [currentProductCharacteristics, setCurrentProductCharacteristics] = useState<ProductCharacteristis[]>([]);

    const [categoryForDelete, setCategoryForDelete] = useState<CategoryModel | null>(null);
    const [productForDelete, setProductForDelete] = useState<ProductionModel | null>(null);

    const [currentImage, setCurrentImage] = useState<any>(null);

    const [alert, setAlert] = useState<AlertModel>({ show: false, message: "", variant: 'success' });

    useEffect(() => {
        setProductions(allProductions);
        setCategoriesWithSubcategories(allCategoriesWithSubcategories);
    }, [allCategoriesWithSubcategories, allProductions])

    const deleteCategory = (event: any) => {
        event.preventDefault();
        if (categoryForDelete) {
            dispatch(categoryDelete(categoryForDelete.id));
            //update state
            if (categoriesWithSubcategories) {
                const nextStateCategoriesWithSubcategories = [...categoriesWithSubcategories];
                if (categoryForDelete.parentId == null) {
                    setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories.filter(x => x.mainCategory.id != categoryForDelete.id));
                }
                else {
                    let stateParentIdx = nextStateCategoriesWithSubcategories?.findIndex(x => x.mainCategory.id == categoryForDelete.parentId)
                    let sybCopy = [...nextStateCategoriesWithSubcategories[stateParentIdx].subcategories]
                    sybCopy = sybCopy.filter(x => x.id != categoryForDelete.id)
                    nextStateCategoriesWithSubcategories[stateParentIdx] = { ...nextStateCategoriesWithSubcategories[stateParentIdx], subcategories: sybCopy };
                    setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories);
                }
            }

            setCategoryForDelete(null);
        }
    }

    const addOrUpdateCategory = (event: any) => {
        event.preventDefault();
        if (currentCategory != null) {
            let name = event.target.name.value;
            let description = event.target.description.value;
            if (currentCategory.id == 0) {
                let request: CategoryModel = { id: 0, name: name, description: description, parentId: currentCategory.parentId, productions: [] }
                dispatch(categoryAdd(request)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        request.id = x.payload;
                        //update state
                        if (categoriesWithSubcategories) {
                            const nextStateCategoriesWithSubcategories = [...categoriesWithSubcategories];
                            if (currentCategory.parentId == null) {
                                nextStateCategoriesWithSubcategories.push({ mainCategory: request, subcategories: [] });
                                setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories);
                            }
                            else {
                                let stateParentIdx = nextStateCategoriesWithSubcategories?.findIndex(x => x.mainCategory.id == currentCategory.parentId)
                                let sybCopy = [...nextStateCategoriesWithSubcategories[stateParentIdx].subcategories]
                                sybCopy.push(request)
                                nextStateCategoriesWithSubcategories[stateParentIdx] = { ...nextStateCategoriesWithSubcategories[stateParentIdx], subcategories: sybCopy };
                                setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories);
                            }
                        }
                    }

                })
            }
            else {
                var updated: CategoryModel = { ...currentCategory, name: name, description: description };
                dispatch(categoryUpdate(updated)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        //update state
                        if (categoriesWithSubcategories) {
                            const nextStateCategoriesWithSubcategories = [...categoriesWithSubcategories];
                            if (currentCategory.parentId == null) {
                                let stateCatIdx = nextStateCategoriesWithSubcategories.findIndex(x => x.mainCategory.id === currentCategory.id)
                                nextStateCategoriesWithSubcategories[stateCatIdx] = { mainCategory: updated, subcategories: nextStateCategoriesWithSubcategories[stateCatIdx].subcategories }
                                setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories);
                            }
                            else {
                                let stateParentIdx = nextStateCategoriesWithSubcategories.findIndex(x => x.mainCategory.id === currentCategory.parentId)
                                const nextSubCategs = [...nextStateCategoriesWithSubcategories[stateParentIdx].subcategories]
                                let stateCatIdx = nextSubCategs.findIndex(x => x.id === currentCategory.id);
                                nextSubCategs[stateCatIdx] = updated;
                                nextStateCategoriesWithSubcategories[stateParentIdx] = { ...nextStateCategoriesWithSubcategories[stateParentIdx], subcategories: nextSubCategs }
                                setCategoriesWithSubcategories(nextStateCategoriesWithSubcategories);
                            }
                        }
                    }
                });
            }
            setCurrentCategory(null);
        }
    }

    const deleteProduction = (event: any) => {
        event.preventDefault();
        if (productForDelete) {
            dispatch(productionDelete(productForDelete.id)).then((x: any) => {
                if (x.error) {
                    setAlert({ show: true, message: x.error.message, variant: 'danger' });
                }
                else {
                    //update state
                    var newProdState = productions?.filter(x => x.id !== productForDelete.id);
                    setProductions(newProdState);
                }
            });
            setProductForDelete(null);
        }
    }

    const addNewCharacteristics = () => {
        if (currentProduct) {
            let updated = [...currentProductCharacteristics, new ProductCharacteristis("", "")]
            setCurrentProductCharacteristics(updated);
        }
    }

    const deleteCharacteristics = (charact: any) => {
        if (currentProduct) {
            let updated = currentProductCharacteristics.filter((x: ProductCharacteristis) => x.id !== charact.id);
            setCurrentProductCharacteristics(updated);
        }
    }

    const addOrUpdateProduction = async (event: any) => {
        event.preventDefault();
        if (currentProduct != null) {
            let name = event.target.name.value;
            let description = event.target.description.value;
            let shortDescription = event.target.shortDescription.value;
            let showInMainPage = event.target.showInMainPage.checked;

            let characteristics: ProductCharacteristis[] = [];

            currentProductCharacteristics.forEach(c => {
                characteristics.push(
                    new ProductCharacteristis(event.target["charactName_" + c.id].value, event.target["charactValue_" + c.id].value))
            });

            let imageId = null;

            if (currentImage) {
                const formData = new FormData();
                formData.append('image', currentImage);
                imageId = await fetchWrapper.postForm(FILE_UPLOAD, formData);
            }

            if (currentProduct.id === 0) {
                let request: ProductionModel = {
                    id: 0,
                    name: name,
                    description: description,
                    showInMainPage: showInMainPage,
                    categoryId: currentProduct.categoryId,
                    characteristics: SetCharacteristicsToString(characteristics),
                    imageId: imageId,
                    shortDescription: shortDescription
                }
                dispatch(productionAdd(request)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        request.id = x.payload;
                        //update state
                        let newProdState;
                        if (productions) {
                            newProdState = [...productions, request];
                        }
                        else {
                            newProdState = [request]
                        }
                        setProductions(newProdState);
                    }
                });

            }
            else {
                let updated: ProductionModel = {
                    ...currentProduct,
                    name: name,
                    description: description,
                    shortDescription: shortDescription,
                    imageId: imageId ?? currentProduct.imageId,
                    characteristics: SetCharacteristicsToString(characteristics),
                    showInMainPage: showInMainPage
                };
                dispatch(productionUpdate(updated)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        //update state
                        if (productions) {
                            const nextStateProduct = [...productions];
                            let stateProductIdx = productions?.findIndex(x => x.id === currentProduct.id)
                            nextStateProduct[stateProductIdx] = updated;
                            setProductions(nextStateProduct);
                        }
                    }
                });

                setCurrentProduct(null);
                setCurrentImage(null);
                setCurrentProductCharacteristics([]);
            }
        }
    }
    
    function uploadImage(event: any) {
        setCurrentImage(event.target.files[0]);
    }

    function renderCategoriesAndProductionsBlock() {
        return (<>
            {
                categoriesWithSubcategories && productions &&
                <div>
                    <Button
                        variant='outline-success'
                        style={{ marginLeft: '10px' }}
                        size='sm'
                        onClick={() => setCurrentCategory({ id: 0, parentId: null })}
                    >
                        + Категория
                    </Button>
                    <hr></hr>
                    <Row>
                        <Col>
                            {categoriesWithSubcategories.map((x: any) => (
                                <Row key={x.mainCategory.id}>
                                    <p>
                                        <div style={{ display: "inline-flex" }}>
                                            <h2>{x.mainCategory.name}</h2>
                                            <Button
                                                variant='outline-primary'
                                                size='sm'
                                                style={{ marginLeft: '10px' }}
                                                onClick={() => { setCurrentCategory(x.mainCategory) }}
                                            >
                                                <Pencil />
                                            </Button>
                                            <Button
                                                variant='outline-success'
                                                style={{ marginLeft: '10px' }}
                                                size='sm'
                                                onClick={() => setCurrentCategory({ id: 0, parentId: x.mainCategory.id })}
                                            >
                                                + подкатегория
                                            </Button>
                                            <Button
                                                variant='outline-danger'
                                                style={{ marginLeft: '10px' }}
                                                size='sm'
                                                onClick={() => setCategoryForDelete(x.mainCategory)}
                                            >
                                                Удалить
                                            </Button>
                                        </div>
                                    </p>

                                    {x.subcategories.map((s: CategoryModel) => (
                                        <p>
                                            <Row key={s.id}>
                                                <p>
                                                    <div style={{ display: "inline-flex" }}>
                                                        <h5>{s.name}</h5>
                                                        <Button
                                                            variant='outline-primary'
                                                            size='sm'
                                                            style={{ marginLeft: '10px' }}
                                                            onClick={() => setCurrentCategory(s)}
                                                        >
                                                            <Pencil />
                                                        </Button>
                                                        <Button
                                                            variant='outline-success'
                                                            style={{ marginLeft: '10px' }}
                                                            size='sm'
                                                            onClick={() => setCurrentProduct({ id: 0, categoryId: s.id })}
                                                        >
                                                            + продукт
                                                        </Button>
                                                        <Button
                                                            variant='outline-danger'
                                                            style={{ marginLeft: '10px' }}
                                                            size='sm'
                                                            onClick={() => setCategoryForDelete(s)}
                                                        >
                                                            Удалить
                                                        </Button>
                                                    </div>
                                                </p>

                                                {productions.filter(p => p.categoryId == s.id)?.map((p: ProductionModel) => (
                                                    <Col lg={4} md={4} sm={6} xs={6} style={{ paddingBottom: "15px" }} key={p.id}>
                                                        <Card className="product-card" key={p.id}>
                                                            <Card.Header>
                                                                <Button
                                                                    variant='outline-primary'
                                                                    size='sm'
                                                                    style={{ marginLeft: '10px' }}
                                                                    onClick={() => {
                                                                        setCurrentProduct(p);
                                                                        setCurrentProductCharacteristics(GetCharacteristicsFromString(p.characteristics));
                                                                    }}
                                                                >
                                                                    <Pencil />
                                                                </Button>
                                                                <Button
                                                                    variant='outline-danger'
                                                                    style={{ marginLeft: '10px' }}
                                                                    size='sm'
                                                                    onClick={() => setProductForDelete(p)}
                                                                >
                                                                    Удалить
                                                                </Button>
                                                                {p.showInMainPage &&
                                                                    <h3 style={{ float: "right" }}>
                                                                        <Clipboard2Heart color=""></Clipboard2Heart>
                                                                    </h3>
                                                                }

                                                            </Card.Header>
                                                            <Card.Img src={`/api/${FILE_DOWNLOAD}?id=${p.imageId}`}></Card.Img>
                                                            <Card.Body>
                                                                <Card.Title>
                                                                    {p.name}
                                                                </Card.Title>
                                                                <Card.Text>
                                                                    <p>Описание: {p.description}</p>
                                                                    <p>Краткое описание: {p.shortDescription}</p>
                                                                    <p>
                                                                        <span>
                                                                            Характеристики:
                                                                        </span>
                                                                        {GetCharacteristicsFromString(p.characteristics).map(c => (
                                                                            <Row>
                                                                                <Col style={{ fontWeight: "bold" }}>{c.name}</Col>
                                                                                <Col>{c.value}</Col>
                                                                            </Row>
                                                                        ))}
                                                                    </p>

                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </p>
                                    ))
                                    }
                                </Row>
                            ))}
                        </Col>
                    </Row>
                </div>

            }

            {currentCategory &&
                <Modal show={currentCategory != null} onHide={() => setCurrentCategory(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {currentCategory.id === 0 && "Добавить категорию"}
                            {currentCategory.id !== 0 && "Редактировать категорию"}
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={addOrUpdateCategory}>
                        <Modal.Body>

                            {currentCategory?.parentId && currentCategory.id === 0 &&
                                <p>
                                    <span>Родительская категория: <b>{categoriesWithSubcategories?.find(x => x.mainCategory.id === currentCategory.parentId)?.mainCategory.name}</b></span>
                                </p>
                            }
                            <p>
                                <input
                                    placeholder="Введите имя категории"
                                    className="form-control"
                                    name="name"
                                    defaultValue={currentCategory?.name}
                                    required
                                />
                            </p>
                            <p>
                                <textarea
                                    placeholder="Введите описание категории"
                                    className="form-control"
                                    name="description"
                                    defaultValue={currentCategory?.description}
                                    required
                                />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => setCurrentCategory(null)}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Сохранить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }

            {categoryForDelete &&
                <Modal show={categoryForDelete != null} onHide={() => setCategoryForDelete(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Точно удалить категорию {categoryForDelete.name}?</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={deleteCategory}>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => setCategoryForDelete(null)}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Удалить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }


            {productForDelete &&
                <Modal show={productForDelete != null} onHide={() => setProductForDelete(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Точно удалить продукт {productForDelete.name}?</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={deleteProduction}>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => setProductForDelete(null)}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Удалить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }


            {currentProduct &&
                <Modal show={currentProduct != null} onHide={() => {
                    setCurrentImage(null)
                    setCurrentProduct(null)
                    setCurrentProductCharacteristics([])
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {currentProduct.id === 0 && "Добавить продукт"}
                            {currentProduct.id !== 0 && "Редактировать продукт"}
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={addOrUpdateProduction}>
                        <Modal.Body>
                            <p>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={currentProduct.name}
                                    placeholder="Название"
                                    name="name"
                                />
                            </p>
                            <p>
                                <textarea
                                    className="form-control"
                                    defaultValue={currentProduct.description}
                                    placeholder="Описание"
                                    name="description"
                                />
                            </p>
                            <p>
                                <textarea
                                    className="form-control"
                                    defaultValue={currentProduct.shortDescription}
                                    placeholder="Описание (отображается на главной)"
                                    name="shortDescription"
                                />
                            </p>
                            <hr></hr>
                            <p>
                                <span>Характеристики:</span>
                                <Button
                                    variant='outline-success'
                                    style={{ marginLeft: '10px' }}
                                    size='sm'
                                    onClick={() => addNewCharacteristics()}
                                >
                                    + Характеристика
                                </Button>
                            </p>
                            <p>
                                {currentProductCharacteristics.map((charact: ProductCharacteristis) => (
                                    <Row key={charact.id}>
                                        <Col><input
                                            type="text"
                                            className="form-control"
                                            defaultValue={charact.name}
                                            placeholder="Название"
                                            name={"charactName_" + charact.id}
                                        /></Col>
                                        <Col><input
                                            type="text"
                                            className="form-control"
                                            defaultValue={charact.value}
                                            placeholder="Значение"
                                            name={"charactValue_" + charact.id}
                                        /></Col>
                                        <Col>
                                            <Button
                                                variant='outline-success'
                                                style={{ marginLeft: '10px' }}
                                                size='sm'
                                                onClick={() => deleteCharacteristics(charact)}
                                            >
                                                Удалить
                                            </Button>
                                        </Col>
                                    </Row>
                                ))}
                            </p>
                            <hr></hr>
                            <p>
                                <span>Показывать на главной: </span>
                                <input
                                    type="checkbox"
                                    defaultChecked={currentProduct.showInMainPage}
                                    placeholder="Показывать на главной"
                                    name="showInMainPage"
                                />
                            </p>
                            <p>
                                {currentImage && (
                                    <p>
                                        <p>
                                            <img
                                                alt="not found"
                                                width={"250px"}
                                                src={URL.createObjectURL(currentImage)}
                                            />
                                        </p>
                                        <p>
                                            <Button
                                                variant='outline-danger'
                                                size='sm'
                                                onClick={() => setCurrentImage(null)}
                                            >
                                                Удалить
                                            </Button>
                                        </p>

                                    </p>
                                )}

                                <input
                                    type="file"
                                    name="image"
                                    onChange={(event) => uploadImage(event)}
                                />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => {
                                setCurrentImage(null)
                                setCurrentProduct(null)
                            }}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Сохранить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>);
    }

    function onCloseAlert() {
        setAlert({ ...alert, show: false })
    }

    return (
        <Container style={{ paddingTop: "80px", paddingBottom: "10px" }}>

            <CustomAlert variant={alert.variant}
                message={alert.message}
                show={alert.show}
                onClose={onCloseAlert}
                dismissible>
            </CustomAlert>

            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Категории и продукция</Accordion.Header>
                    <Accordion.Body>
                        {renderCategoriesAndProductionsBlock()}
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>Новости</Accordion.Header>
                    <Accordion.Body>
                        <EditNewsComponent></EditNewsComponent>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Container>
    )
}
