import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import { RootState } from "../../_store";
import { FILE_DOWNLOAD } from "../../constants/endpoints";
import { ProductionModel } from "../../models/production/ProductionModel";
import { history } from './../../_helpers/history';
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../components/windiws.dimensions";

export default function FavoritesProductsSection() {

    history.navigate = useNavigate();
    history.location = useLocation();
    const { width } = useWindowDimensions();

    const allProductions = useSelector((state: RootState) => state.production.productions);

    const getFavoriteProductions = () => {

        return allProductions.filter((x: ProductionModel) => x.showInMainPage);
    };

    const xsColSize = 6;

    return (
        <Container className="page-container">
            <h2 className="page-container-header">Наши избранные продукты</h2>
            {
                allProductions &&
                <Row>
                    {getFavoriteProductions()?.map((p: ProductionModel) => (
                        <Col lg={3} md={4} sm={6} xs={xsColSize} style={{ padding: "5px" }} key={p.id}>
                            <Card className="product-card" key={p.id} id={`${p.id}`} onClick={() => history.navigate(`/production/${p.id}`)}>
                                <Card.Img src={`/api/${FILE_DOWNLOAD}?id=${p.imageId}`} loading="lazy"></Card.Img>
                                <Card.Body>
                                    <Card.Title>
                                        {p.name}
                                    </Card.Title>
                                    {width > 575 && (<Card.Text>
                                        <p className="card-description adaptive-font-size">{p.shortDescription}</p>
                                    </Card.Text>)}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

            }
        </Container>
    )
}