import { history } from './history';

export const fetchWrapper = {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    postForm: postForm()
};

function request(method: any) {
    return (url: any, body?: any) => {
        const requestOptions: any = {
            method,
            headers: authHeader()
        };
        if (body) {
            requestOptions.headers['Content-Type'] = 'application/json';
            requestOptions.body = JSON.stringify(body);
        }
        return fetch(`/api/` + url, requestOptions).then(handleResponse);
    }
}

function postForm() {
    return (url: any, body?: any) => {
        const requestOptions: any = {
            method: 'POST',
            headers: authHeader()
        };
        if (body) {
            requestOptions.body = body;
        }
        return fetch(`/api/` + url, requestOptions).then(handleResponse);
    }
}

function authHeader() {
    const token = authToken();
    const isLoggedIn = !!token;
    if (isLoggedIn) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

function authToken() {
    return JSON.parse(localStorage.getItem('user') || 'null')?.token;
}

function handleResponse(response: any) {
    return response.text().then((text: any) => {
        let data;
        try {
            data = text && JSON.parse(text);
        }
        catch {
            data = text;
        }

        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                localStorage.removeItem('user');
                history.navigate('/login');
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}