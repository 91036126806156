import { createAsyncThunk } from '@reduxjs/toolkit'
import { fetchWrapper } from '../../_helpers/fetch-wrapper'
import { AUTH, AUTH_CHECK_TOKEN } from '../../constants/endpoints';
import { categoriesGetList, categoriesWithSubcategoriesList } from './category.thunk';
import { newsGetList } from './news.thunk';
import { productionsGetList } from './production.thunk';

export const loginThunk = createAsyncThunk(
    'auth/login',
    async ({ login, password }: any) => {
        const accessRule: any[] = await fetchWrapper.post(AUTH, { login, password });
        return accessRule;
    }
);

export const checkTokenThunk = createAsyncThunk(
    'auth/checkToken',
    async ({ token }: any) => {
        console.log('checkTokenThunk');
        const isValid: boolean = await fetchWrapper.post(AUTH_CHECK_TOKEN, { token });
        return isValid;
    }
);

export const appBootstrap = createAsyncThunk(
    'App/Bootstrap',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(categoriesGetList());
        thunkAPI.dispatch(categoriesWithSubcategoriesList());
        thunkAPI.dispatch(newsGetList());
        thunkAPI.dispatch(productionsGetList());
    }
)
