import { createSlice } from "@reduxjs/toolkit";
import { ProductionModel } from "../../models/production/ProductionModel";
import { productionAdd, productionDelete, productionUpdate, productionsGetList } from "../thunks/production.thunk";

export interface IProductionInitialState {
    productions: ProductionModel[]
}

const initialState: IProductionInitialState = {
    productions: []
}

const productionSlice = createSlice({
    name: "production",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(productionsGetList.fulfilled, (state: any, action: any) => {
                state.productions = action.payload;
            })
            .addCase(productionAdd.fulfilled, (state: any, action: any) => {

            })
            .addCase(productionUpdate.fulfilled, (state: any, action: any) => {

            })
            .addCase(productionDelete.fulfilled, (state: any, action: any) => {

            })
    }
});

const productionReducer = productionSlice.reducer;
export { productionReducer };