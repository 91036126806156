import React from "react";
import CompanyPage from "./CompanyPage";
import ContactsPage from "./ContactsPage";
import FavoritesProductsSection from "./mainPageSections/FavoritesProductsSection";
import NewsSection from "./mainPageSections/NewsSection";

export default function MainPage() {
    return (
        <div>     
            <NewsSection/>
            <FavoritesProductsSection/>
            <CompanyPage/>
            <ContactsPage/>
        </div>
    );
}