import { configureStore } from '@reduxjs/toolkit';
import { authReducer } from './slices/auth.slice';
import { categoryReducer } from './slices/category.slice';
import { productionReducer } from './slices/production.slice';
import { newsReducer } from './slices/news.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        category: categoryReducer,
        production: productionReducer,
        news: newsReducer
    }
});

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>