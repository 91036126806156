import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Check2 } from "react-bootstrap-icons";
import useWindowDimensions from "../components/windiws.dimensions";

export default function CompanyPage() {
    const { width } = useWindowDimensions();

    function advantage(imageSrc: string, title: string, description: string) {
        return (
            <Row className="advantage-row">
                <Col xs="2">
                    <div>
                        {/* <img src={imageSrc} style={{ width: "55px" }}></img> */}
                        <Check2 className="check-icon"></Check2>
                    </div>
                </Col>
                <Col style={{ textAlign: "justify" }}>
                    <p className="advantage-title">{title}</p>
                    <span>{description}</span>
                </Col>
            </Row>);
    }

    return (
        <div style={{ backgroundColor: "#F4FBFF" }}>
            <Container className="page-container">
                <h2 className="page-container-header">К-сервис - это:</h2>
                {width <= 850 &&
                    <Row className="justify-content-md-center">
                        <Col>
                            {advantage("./svg/icons/Group-1.svg", "Высокое качество", "")}
                            {advantage("./svg/icons/Group-2.svg", "Широкий ассортимент", "",)}
                            {advantage("./svg/icons/Group-3.svg", "Удобство использования", "")}
                        </Col>
                        <Col>
                            {advantage("./svg/icons/Group-1.svg", "Индивидуальный подход", "")}
                            {advantage("./svg/icons/Group-2.svg", "Надежное сотрудничество", "")}
                            {advantage("./svg/icons/Group-3.svg", "Выгодные цены", "")}
                        </Col>
                    </Row>
                }
                {width > 850 &&
                    <Row className="justify-content-md-center">
                        <Col>
                            {advantage("./svg/icons/Group-1.svg", "Высокое качество",
                                "Мы предлагаем продукцию, изготовленную из самых надежных материалов. Наша упаковка безопасна и сохраняет продукты свежими и вкусными в течение долгого времени")}
                            {advantage("./svg/icons/Group-2.svg", "Широкий ассортимент",
                                "Компания предлагает разнообразные типы и размеры пищевой упаковки, обеспечивая возможность подобрать оптимальное решение для различных типов продуктов")}
                            {advantage("./svg/icons/Group-3.svg", "Удобство использования",
                                "упаковка разработана с учетом удобства использования для потребителей, открывается и закрывается легко, обеспечивает удобное хранение и транспортировку продуктов")}
                        </Col>
                        <Col>
                            {advantage("./svg/icons/Group-1.svg", "Индивидуальный подход",
                                "Мы понимаем, что каждая компания имеет свои уникальные требования. Поэтому мы предлагаем индивидуальный подход к каждому клиенту, учитывая их цели, бюджет и предпочтения")}
                            {advantage("./svg/icons/Group-2.svg", "Надежное сотрудничество",
                                "Мы строим долгосрочные отношения с нашими клиентами, основанные на взаимном доверии и уважении. Мы всегда готовы предоставить свою помощь и консультацию по вопросам пищевой упаковки")}
                            {advantage("./svg/icons/Group-3.svg", "Конкурентоспособные цены",
                                "Благодаря оптимизации процессов производства и поставок, мы можем предложить конкурентоспособные цены на свою продукцию")}

                        </Col>
                    </Row>}

            </Container>
        </div >
    );
}