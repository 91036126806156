export class ProductionModel {
    id: number;
    name: string;
    description: string;
    shortDescription: string;
    imageId: number;
    showInMainPage: boolean;
    characteristics: string;
    categoryId: number;
    constructor(id: number,
        name: string,
        description: string,
        shortDescription: string,
        imageId: number,
        showInMainPage: boolean,
        characteristics: string,
        categoryId: number) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.shortDescription = shortDescription;
        this.imageId = imageId;
        this.showInMainPage = showInMainPage;
        this.characteristics = characteristics;
        this.categoryId = categoryId;
    }
}

export class ProductCharacteristis {
    name: string;
    value: string;
    id: number;
    constructor(name: string,
        value: string, id?: number) {
        this.name = name;
        this.value = value;
        this.id = id ?? Math.random();
    }
}

export function GetCharacteristicsFromString(json: string): ProductCharacteristis[] {
    return json ? JSON.parse(json) as ProductCharacteristis[] : [];
}

export function SetCharacteristicsToString(characteristics: ProductCharacteristis[]): string {
    return JSON.stringify(characteristics);
}
