import React from "react";
import { useSelector } from "react-redux";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RootState } from "../_store";
import { CategoryModel } from "../models/category/CategoryModel";
import { FILE_DOWNLOAD } from "../constants/endpoints";
import { GetCharacteristicsFromString, ProductionModel } from "../models/production/ProductionModel";
import { history } from './../_helpers/history';

export default function ProductionsPage() {
    const { categoryId } = useParams();

    history.navigate = useNavigate();
    history.location = useLocation();

    const allCategoriesWithSubcategories = useSelector((state: RootState) => state.category.categoriesWithSubcategoriesList);
    const allProductions = useSelector((state: RootState) => state.production.productions);

    const getCategories = () => {

        if (categoryId) {
            let categories = allCategoriesWithSubcategories.filter(x => x.mainCategory.id === Number(categoryId));
            return categories;

        }
        return allCategoriesWithSubcategories;
    };

    const xsColSize = 12;

    return (
        <Container className="page-container">
            <h2 className="page-container-header">Наши продукты</h2>
            {
                allCategoriesWithSubcategories && allProductions &&
                <Row>
                    <Col>
                        {getCategories()?.map((x: any) => (
                            <Row key={x.mainCategory.id}>
                                <h2>{x.mainCategory.name}</h2>

                                {x.subcategories.map((s: CategoryModel) => (
                                    <p>
                                        <Row key={s.id}>
                                            <p>
                                                <h5>{s.name}</h5>
                                            </p>

                                            {allProductions.filter(p => p.categoryId === s.id)?.map((p: ProductionModel) => (
                                                <Col lg={4} md={4} sm={6} xs={xsColSize} style={{ padding: "5px" }} key={p.id}>
                                                    <Card className="product-card" key={p.id} id={`${p.id}`} onClick={() => history.navigate(`/production/${p.id}`)}>
                                                        <Card.Img src={`/api/${FILE_DOWNLOAD}?id=${p.imageId}`} loading="lazy"></Card.Img>
                                                        <Card.Body>
                                                            <Card.Title>
                                                                {p.name}
                                                            </Card.Title>
                                                            <Card.Text>
                                                                <p className="card-description adaptive-font-size">{p.shortDescription}</p>
                                                                {GetCharacteristicsFromString(p.characteristics).map(c => (
                                                                    <Row>
                                                                        <Col style={{ fontWeight: "bold" }}>{c.name}</Col>
                                                                        <Col>{c.value}</Col>
                                                                    </Row>
                                                                ))}
                                                            </Card.Text>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </p>
                                ))
                                }
                            </Row>
                        ))}
                    </Col>
                </Row>
            }
        </Container>
    )
}