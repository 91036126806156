import React from "react";
import { Alert } from "react-bootstrap";

export default function CustomAlert(props: any) {
    
    let { message, variant, show, key, onClose, dismissible } = props;

    return (
        <div>
            <Alert key={key} variant={variant} show={show} 
            onClose={() => onClose?.()} dismissible={dismissible}>
                {message}
            </Alert>
        </div>
    );
}