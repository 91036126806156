import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { RootState } from "../_store";
import { useSelector } from "react-redux";
import Moment from 'react-moment';
import { FILE_DOWNLOAD } from "../constants/endpoints";
import { NewsModel } from "../models/NewsModel";

export default function NewsPage() {

    const news = useSelector((state: RootState) => state.news.news);

    return (
        <Container className="page-container">
            <h2 className="page-container-header">Новости компании</h2>
            {news.map((oneNews: NewsModel) => (
                <Row style={{ paddingBottom: "30px" }} key={oneNews.id}>
                    <Col xs={6}>
                        <img src={`/api/${FILE_DOWNLOAD}?id=${oneNews.imageId}`}
                            style={{ width: "100%" }}
                            alt="conpany-news"></img>
                    </Col>
                    <Col>
                        <p>
                            <h4>{oneNews.headline}</h4>
                            <span><Moment format="DD.MM.YYYY">{oneNews.date}</Moment></span>
                        </p>
                        <p>
                            {oneNews.description}
                        </p>
                    </Col>
                </Row>
            )
            )}

        </Container>
    )
}