import { createSlice } from "@reduxjs/toolkit";
import { CategoryModel } from "../../models/category/CategoryModel";
import { categoriesGetList, categoriesWithSubcategoriesList, categoryAdd, categoryDelete, categoryUpdate } from "../thunks/category.thunk";

export interface ICategoryInitialState {
    categoriesWithSubcategoriesList: {
        mainCategory: CategoryModel,
        subcategories: CategoryModel[]
    }[],
    categories: CategoryModel[]
}

const initialState: ICategoryInitialState = {
    categoriesWithSubcategoriesList: [],
    categories: []
}

const categorySlice = createSlice({
    name: "category",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(categoriesWithSubcategoriesList.fulfilled, (state: any, action: any) => {
                state.categoriesWithSubcategoriesList = action.payload;
            })
            .addCase(categoriesGetList.fulfilled, (state: any, action: any) => {
                state.categories = action.payload;
            })
            .addCase(categoryUpdate.fulfilled, (state: any, action: any) => {
            })
            .addCase(categoryAdd.fulfilled, (state: any, action: any) => {
            })
            .addCase(categoryDelete.fulfilled, (state: any, action: any) => {
            });
    }
});

const categoryReducer = categorySlice.reducer;
export { categoryReducer };