import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import { NEWS_ADD, NEWS_DELETE, NEWS_GET_LIST, NEWS_UPDATE } from "../../constants/endpoints";
import { NewsModel } from "../../models/NewsModel";

export const newsGetList = createAsyncThunk(
    'news/list',
    async () => {
        const news: NewsModel[] = await fetchWrapper.get(`${NEWS_GET_LIST}`);
        return news;
    }
);

export const newsAdd = createAsyncThunk<any, NewsModel>(
    'news/add',
    async (request) => {
        const id: number = await fetchWrapper.post(NEWS_ADD, request);
        return id;
    }
);

export const newsDelete = createAsyncThunk<any, number>(
    'news/delete',
    async (id) => {
        await fetchWrapper.delete(`${NEWS_DELETE}?id=${id}`);
    }
);

export const newsUpdate = createAsyncThunk<any, NewsModel>(
    'news/update',
    async (request) => {
        await fetchWrapper.put(NEWS_UPDATE, request);
    }
);