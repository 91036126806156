import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../_store";
import { NewsModel } from "../../models/NewsModel";
import React from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import { Pencil, Clipboard2Heart } from "react-bootstrap-icons";
import { FILE_DOWNLOAD, FILE_UPLOAD } from "../../constants/endpoints";
import Moment from "react-moment";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import { newsAdd, newsDelete, newsUpdate } from "../../_store/thunks/news.thunk";
import BootstrapDatePickerComponent from "../BootstrapDatePickerComponent";
import { AlertModel } from "../../models/AlertModel";

export default function EditNewsComponent() {

    const dispatch = useDispatch<AppDispatch>()
    const allNews = useSelector((state: RootState) => state.news.news);

    const [news, setNews] = useState<NewsModel[]>();
    const [currentNews, setCurrentNews] = useState<any | null>(null);
    const [newsForDelete, setNewsForDelete] = useState<NewsModel | null>(null);
    const [currentImage, setCurrentImage] = useState<any>(null);

    const [alert, setAlert] = useState<AlertModel>({ show: false, message: "", variant: 'success' });


    useEffect(() => {
        setNews(allNews);
    }, [allNews])

    function renderNewsBlock() {
        return (<>
            <Button
                variant='outline-success'
                style={{ marginLeft: '10px' }}
                size='sm'
                onClick={() => setCurrentNews({ id: 0 })}
            >
                Добавить новость
            </Button>
            <hr></hr>
            {news?.map((oneNews) => (
                <Row style={{ paddingBottom: "30px" }} key={oneNews.id}>
                    <Col xs={3}>
                        <img src={`/api/${FILE_DOWNLOAD}?id=${oneNews.imageId}`}
                            style={{ width: "100%" }}
                            alt="conpany-news"></img>
                    </Col>
                    <Col>
                        <Button
                            variant='outline-primary'
                            size='sm'
                            style={{ marginLeft: '10px' }}
                            onClick={() => {
                                setCurrentNews(oneNews);
                            }}
                        >
                            <Pencil />
                        </Button>
                        <Button
                            variant='outline-danger'
                            style={{ marginLeft: '10px' }}
                            size='sm'
                            onClick={() => setNewsForDelete(oneNews)}
                        >
                            Удалить
                        </Button>
                        {oneNews.showInMainPage &&
                            <h3 style={{ float: "right" }}>
                                <Clipboard2Heart color=""></Clipboard2Heart>
                            </h3>
                        }
                        <p>
                            <h4>{oneNews.headline}</h4>
                            <span><Moment format="DD.MM.YYYY">{oneNews.date}</Moment></span>
                        </p>
                        <p>
                            {oneNews.description}
                        </p>
                    </Col>
                </Row>
            )
            )}

            {currentNews &&
                <Modal show={currentNews != null} onHide={() => {
                    setCurrentImage(null)
                    setCurrentNews(null)
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {currentNews.id === 0 && "Добавить новость"}
                            {currentNews.id !== 0 && "Редактировать новость"}
                        </Modal.Title>
                    </Modal.Header>
                    <form onSubmit={addOrUpdateNews}>
                        <Modal.Body>
                            <p>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={currentNews.headline}
                                    placeholder="Заголовок"
                                    name="headline"
                                />
                            </p>
                            <p>
                                <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={currentNews.description}
                                    placeholder="Описание"
                                    name="description"
                                />
                            </p>
                            <p>
                                <BootstrapDatePickerComponent></BootstrapDatePickerComponent>
                            </p>
                            <p>
                                <span>Показывать на главной: </span>
                                <input
                                    type="checkbox"
                                    defaultChecked={currentNews.showInMainPage}
                                    placeholder="Показывать на главной"
                                    name="showInMainPage"
                                />
                            </p>
                            <p>
                                {currentImage && (
                                    <p>
                                        <p>
                                            <img
                                                alt="not found"
                                                width={"250px"}
                                                src={URL.createObjectURL(currentImage)}
                                            />
                                        </p>
                                        <p>
                                            <Button
                                                variant='outline-danger'
                                                size='sm'
                                                onClick={() => setCurrentImage(null)}
                                            >
                                                Удалить
                                            </Button>
                                        </p>

                                    </p>
                                )}

                                <input
                                    type="file"
                                    name="image"
                                    onChange={(event) => uploadImage(event)}
                                />
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => {
                                setCurrentImage(null)
                                setCurrentNews(null)
                            }}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Сохранить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }

            {newsForDelete &&
                <Modal show={newsForDelete != null} onHide={() => setNewsForDelete(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Точно удалить новость {newsForDelete.headline}?</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={deleteNews}>
                        <Modal.Footer>
                            <Button variant='secondary' onClick={() => setNewsForDelete(null)}>
                                Отмена
                            </Button>
                            <Button type="submit" variant='success'>Удалить</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            }
        </>)
    }

    const addOrUpdateNews = async (event: any) => {
        event.preventDefault();
        if (currentNews != null) {
            let headline = event.target.headline.value;
            let description = event.target.description.value;
            let date = event.target.date.value;
            let showInMainPage = event.target.showInMainPage.checked;

            let imageId = null;

            if (currentImage) {
                const formData = new FormData();
                formData.append('image', currentImage);
                imageId = await fetchWrapper.postForm(FILE_UPLOAD, formData);
            }

            if (currentNews.id === 0) {
                let request: NewsModel = {
                    id: 0,
                    headline: headline,
                    description: description,
                    showInMainPage: showInMainPage,
                    date: date,
                    imageId: imageId,
                }

                dispatch(newsAdd(request)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        //update state
                        let newState;
                        if (news) {
                            newState = [...news, request];
                        }
                        else {
                            newState = [request]
                        }
                        setNews(newState);
                        request.id = x.payload
                    }
                });
            }
            else {
                let updated: NewsModel = {
                    ...currentNews,
                    headline: headline,
                    description: description,
                    date: date,
                    imageId: imageId ?? currentNews.imageId,
                    showInMainPage: showInMainPage
                };
                dispatch(newsUpdate(updated)).then((x: any) => {
                    if (x.error) {
                        setAlert({ show: true, message: x.error.message, variant: 'danger' });
                    }
                    else {
                        ///update state
                        if (news) {
                            const nextStateNews = [...news];
                            let stateNewsIdx = news?.findIndex(x => x.id === currentNews.id)
                            nextStateNews[stateNewsIdx] = updated;
                            setNews(nextStateNews);
                        }
                    }
                });
            }

            setCurrentNews(null);
            setCurrentImage(null);
        }
    }

    const deleteNews = (event: any) => {
        event.preventDefault();
        if (newsForDelete) {
            dispatch(newsDelete(newsForDelete.id));
            setNewsForDelete(null);
            //update state
            var newState = news?.filter(x => x.id !== newsForDelete.id);
            setNews(newState);
        }
    }

    function uploadImage(event: any) {
        setCurrentImage(event.target.files[0]);
    }

    return (renderNewsBlock());
}