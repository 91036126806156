import { createSlice } from "@reduxjs/toolkit";
import { NewsModel } from "../../models/NewsModel";
import { newsAdd, newsDelete, newsGetList, newsUpdate } from "../thunks/news.thunk";

export interface INewsInitialState {
    news: NewsModel[]
}

const initialState: INewsInitialState = {
    news: []
}

const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {},
    extraReducers: (builder: any) => {
        builder
            .addCase(newsGetList.fulfilled, (state: any, action: any) => {
                state.news = action.payload;
            })
            .addCase(newsAdd.fulfilled, (state: any, action: any) => {

            })
            .addCase(newsUpdate.fulfilled, (state: any, action: any) => {

            })
            .addCase(newsDelete.fulfilled, (state: any, action: any) => {

            })
    }
});

const newsReducer = newsSlice.reducer;
export { newsReducer };