import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { history } from './../_helpers/history';
import React from 'react';
import { RootState } from '../_store';

export { PrivateRoute };

function PrivateRoute({children}: any) {
    const user = useSelector((state: RootState) => state.auth.user);

    if (!user) {
        return <Navigate to="/login" state={{ from: history.location }}/>
    }

    return children;
}