import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import { CATEGORY_ADD, CATEGORY_DELETE, CATEGORY_GET_LIST, CATEGORY_GET_WITH_SUBCATEGORIES, CATEGORY_UPDATE } from "../../constants/endpoints";
import { CategoryModel } from "../../models/category/CategoryModel";

export const categoriesGetList = createAsyncThunk(
    'category/list',
    async () => {
        const categories: any[] = await fetchWrapper.get(`${CATEGORY_GET_LIST}`);
        return categories;
    }
);

export const categoriesWithSubcategoriesList = createAsyncThunk(
    'category/categoriesWithSubcategoriesList',
    async () => {
        const categories: any[] = await fetchWrapper.get(`${CATEGORY_GET_WITH_SUBCATEGORIES}`);
        return categories;
    }
);

export const categoryUpdate = createAsyncThunk<any, CategoryModel>(
    'category/update',
    async (request) => {
        await fetchWrapper.put(`${CATEGORY_UPDATE}`, request);
    }
);

export const categoryAdd = createAsyncThunk<any, CategoryModel>(
    'category/add',
    async (request) => {
        const id: number = await fetchWrapper.post(`${CATEGORY_ADD}`, request);
        return id;
    }
);
export const categoryDelete = createAsyncThunk<any, number>(
    'category/delete',
    async (id) => {
        const categories: any[] = await fetchWrapper.delete(`${CATEGORY_DELETE}?id=${id}`);
        return categories;
    }
);