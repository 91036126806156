import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { RootState } from "../_store";
import { FILE_DOWNLOAD } from "../constants/endpoints";
import { GetCharacteristicsFromString, ProductionModel } from "../models/production/ProductionModel";

export default function ProductionPage() {
    const { id } = useParams();

    const [product, setProduct] = useState<ProductionModel>();

    const productions = useSelector((state: RootState) => state.production.productions);

    useEffect(() => {
        if (id) {
            setProduct(productions.find(x => x.id === Number(id)));
        }
    }, [id, productions])


    return (
        <Container className="page-container">
            {product &&
                <>
                    <Row style={{ paddingBottom: "30px", paddingTop: "30px" }}>
                        <Col xs={6}>
                            <img src={`/api/${FILE_DOWNLOAD}?id=${product.imageId}`}
                                style={{ width: "100%" }}
                                alt={`product-${product.imageId}`}></img>
                        </Col>
                        <Col className="adaptive-font-size">
                            <p>
                                <h4>{product.name}</h4>
                            </p>
                            <p className="card-description">
                                {product.description}
                            </p>
                            <p>
                                {GetCharacteristicsFromString(product.characteristics).map(c => (
                                    <Row>
                                        <Col style={{ fontWeight: "bold" }}>{c.name}</Col>
                                        <Col>{c.value}</Col>
                                    </Row>
                                ))}
                            </p>
                        </Col>
                    </Row>
                </>
            }


        </Container>
    )
}