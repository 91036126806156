export const AUTH = 'auth/login'
export const AUTH_CHECK_TOKEN = 'auth/CheckToken'

export const CATEGORY_GET_BY_ID = 'category/get'
export const CATEGORY_GET_LIST = 'category/list'
export const CATEGORY_GET_WITH_SUBCATEGORIES = 'category/CategoriesWithSubcategoriesList'
export const CATEGORY_UPDATE = 'category/update'
export const CATEGORY_ADD = 'category/add'
export const CATEGORY_ADD_FILE = 'category/AddFromFile'
export const CATEGORY_DELETE = 'category/delete'

export const PRODUCTION_GET_LIST = 'production/list'
export const PRODUCTION_ADD = 'production/add'
export const PRODUCTION_UPDATE = 'production/update'
export const PRODUCTION_DELETE = 'production/delete'

export const NEWS_GET_LIST = 'news/list'
export const NEWS_ADD = 'news/add'
export const NEWS_UPDATE = 'news/update'
export const NEWS_DELETE = 'news/delete'

export const FILE_UPLOAD = 'file/UploadImage'
export const FILE_DOWNLOAD = 'file/DownloadImage'