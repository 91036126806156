import React from "react";
import { Button, Carousel, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Moment from 'react-moment';
import { Link } from "react-router-dom";
import { NewsModel } from "../../models/NewsModel";
import { RootState } from "../../_store";
import { FILE_DOWNLOAD } from "../../constants/endpoints";

export default function NewsSection() {

    const news = useSelector((state: RootState) => state.news.news);

    const getNews = () => {

        return news.filter((x: NewsModel) => x.showInMainPage);
    }

    return (
        <Container className="page-container">
            <h2 className="page-container-header">Новости компании</h2>
            <Carousel fade>
                {getNews().map((oneNews: NewsModel) => (
                    <Carousel.Item interval={2000}>
                        <img src={`/api/${FILE_DOWNLOAD}?id=${oneNews.imageId}`}
                            id='carousel-news-image'
                            alt="conpany-news"></img>
                        <Carousel.Caption>
                            <h3>{oneNews.headline}</h3>
                            <p><Moment format="DD.MM.YYYY">{oneNews.date}</Moment></p>
                            {/* <p><Button size="sm" className="get-more-news-info-button">
                                Узнать больше
                            </Button></p> */}
                        </Carousel.Caption>
                    </Carousel.Item>
                )
                )}
            </Carousel>
            <Row>
                <Col xs={11}>
                </Col>
                <Col>
                    <Link to={`/news`}
                        style={{ float: "right", margin: "10px 0px 10px 0px" }}>Больше новостей...{'>'}</Link>
                </Col>
            </Row>


        </Container>
    )
}