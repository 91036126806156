import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function ContactsPage() {
    return (
        <Container className="page-container">
            <h2 className="page-container-header">Контактная информация</h2>
            <Row wrap>
                <Col style={{minWidth: "330px"}}>
                    <div className="adaptive-font-size">                        
                        <p style={{textAlign: "justify" }}>
                        В данном разделе вы найдете все необходимые контактные данные, которые позволят нам оставаться на связи. 
                        Если у вас возникли вопросы или предложения, не стесняйтесь обратиться. Мы всегда рады сотрудничеству.
                        Свяжитесь с нами прямо сейчас, и мы постараемся помочь вам наилучшим образом!
                        </p>
                        <div style={{ textAlign: "end" }}>
                            <p>С Уважением к Вам и Вашему делу,</p>
                            <p>Директор ООО «К-Сервис»</p>
                            <p>Зверков Кирилл Андреевич</p>
                        </div>
                        <Row>
                            <Col>
                                <p>По вопросам сотрудничества:</p>
                                <p><strong>Зверков Кирилл Андреевич</strong></p>
                                <p>
                                    Тел.: <a href="tel:+79284010773">+7(928)401-07-73</a>
                                </p>
                                <p>
                                    Почта: <a href="mailto:ca-pack@mail.ru">ca-pack@mail.ru</a>
                                </p>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{minWidth: "370px"}}>
                    <div className="map-wrapper">
                        <iframe src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=91681792555" 
                        title="k-serv-company-yandex-map"
                        style={{ minHeight: "400px", width: "100%" }}></iframe>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}