import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWrapper } from "../../_helpers/fetch-wrapper";
import { PRODUCTION_ADD, PRODUCTION_DELETE, PRODUCTION_GET_LIST, PRODUCTION_UPDATE } from "../../constants/endpoints";
import { ProductionModel } from "../../models/production/ProductionModel";

export const productionsGetList = createAsyncThunk(
    'production/list',
    async () => {
        const productions: any[] = await fetchWrapper.get(PRODUCTION_GET_LIST);
        return productions;
    }
);

export const productionAdd = createAsyncThunk<any, ProductionModel>(
    'production/add',
    async (request) => {
        const id: number = await fetchWrapper.post(PRODUCTION_ADD, request);
        return id;
    }
);

export const productionDelete = createAsyncThunk<any, number>(
    'production/delete',
    async (id) => {
        await fetchWrapper.delete(`${PRODUCTION_DELETE}?id=${id}`);
    }
);

export const productionUpdate = createAsyncThunk<any, ProductionModel>(
    'production/update',
    async (request) => {
        await fetchWrapper.put(PRODUCTION_UPDATE, request);
    }
);