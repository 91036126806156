import { createSlice } from '@reduxjs/toolkit';
import { history } from '../../_helpers/history';
import { appBootstrap, checkTokenThunk, loginThunk } from '../thunks/auth.thunks';

const initialState = createInitialState();

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        logout(state) {
            state.user = null;
            localStorage.removeItem('user');
            history.navigate('/login');
        },
    },
    extraReducers: (builder) => {
        builder
            // Получение данных работника
            .addCase(loginThunk.fulfilled, (state, action) => {
                const user = action.payload;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                state.user = user;
                // get return url from location state or default to home page
                const { from } = history.location.state || { from: { pathname: '/' } };
                history.navigate(from);
            })
            // Проверка токена
            .addCase(checkTokenThunk.fulfilled, (state, action) => {
                const tokenIsValid = action.payload;
                if(!tokenIsValid){
                    localStorage.removeItem('user');
                    state.user = null;
                    history.navigate('/login');
                }      
            })
            .addCase(appBootstrap.fulfilled, (state, action) => {

            })
    }
});

function createInitialState() {
    let user: any = localStorage.getItem('user');
    if (user != null && user != 'undefined') {
        user = JSON.parse(user)
    }
    return {
        user: user,
        error: null
    }
}

const authReducer = authSlice.reducer

export const { logout } = authSlice.actions

export { authReducer }