import React from 'react'
import { Form } from 'react-bootstrap';
class BootstrapDatePickerComponent extends React.Component{
    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <Form.Group controlId="dob">
                            <Form.Label>Выберите дату</Form.Label>
                            <Form.Control type="date" name="date" />
                        </Form.Group>
                    </div>
                </div>
            </div>
        )
    }
    
}
export default BootstrapDatePickerComponent;